$yellow: #f8f0bb;
$pink: #eebec4;
$green: #a6cbcf;

* {
  box-sizing: border-box;
  max-width: 100%;
}

body {
  font-family: sans-serif;
  text-align: center;
  background-color: rgb(234, 236, 234);
  padding: 0;
  margin: 0;
  min-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 200px;
}

h2 {
  margin-top: 100px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding: 0 16px;
  max-width: 600px;
  margin: 0 auto;
}

.row:first-of-type {
  border-top: 1px solid lightgrey;
}

.row p {
  width: 33%;
}

.row p:first-of-type {
  text-align: left;
}

.row p:last-of-type {
  text-align: right;
}

h2 {
  text-align: center;
  width: 100%;
}

.row ul {
  list-style: none;
  font-size: 90%;
  padding: 0;
  margin: 0;
}

.cartTotal {
  font-size: 50px;
}

a {
  transition: 0.3s ease;
}

.ignore.a {
  padding: 8px 16px;
  /* background: lightseagreen; */
  background: white;
  font-size: 24px;
  color: black;
  margin: 8px;
  border-radius: 10px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.5px;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.ignore.a:hover {
  background-color: teal;
}

.products {
  display: flex;
  flex-wrap: wrap;
}

.products h4 {
  width: 100%;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.variation {
  padding: 8px;
  background: white;
  border-radius: 10px;
}

.cart p {
  text-align: left;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid lightgrey;
  padding: 8px;
}

.cart strong {
  text-align: right;
  float: right;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Verdana ", sans-serif;
}

.selected {
  font-weight: bold;
  color: $green;
  text-decoration: underline;
}

.pos {
  text-align: left;
  border: 0;
  height: 100svh;
  width: 100svw;

  padding: 16px;
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;

  // A6CBCF 0%
  // EEBEC4 50%
  // F8F0BB 100%
  background: rgb(166, 203, 207);
  background: linear-gradient(
    315deg,
    rgba(166, 203, 207, 1) 0%,
    rgba(238, 190, 196, 1) 50%,
    rgba(248, 240, 187, 1) 100%
  );

  &__container {
    display: flex;
    height: 95%;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    overflow: hidden;
  }

  &__cart,
  &__menu {
    padding: 16px;
    border: 0;
  }

  &__menu {
    width: 80px;
    background: lightgrey;
    border-right: 1px solid white;

    // no menu items yet
    display: none;
  }

  &__stock-list {
    flex-grow: 1;
    overflow-y: scroll;

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    .card {
      min-width: 150px;
      width: 30%;
      //border: 1px solid;
      border-radius: 8px;
      margin-right: 8px;
      margin-bottom: 8px;
      text-align: center;
      padding: 16px;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: rgba(255, 255, 255, 0.7);
      border: 1px solid lightgrey;

      &__price {
        font-size: 1.5rem;
        margin: 0;
      }

      &__title {
        // font-weight: bold;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

    display: flex;

    &__category {
      width: 25%;
      overflow-y: scroll;
      padding: 16px 0 16px 16px;

      a {
        font-family: "Open Sans", sans-serif;
        display: block;
        background: rgba(255,255,255, .3);
        padding: 8px 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.01em;
        border-radius: 10px 0 0 10px;
        margin-bottom: 10px;
        border: 1px solid lightgrey;
        border-right: 0;

        &.active {
          background: rgba(255,255,255,1);
        }
      }
    }

    &__variations {
      width: 75%;
      background: white;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid lightgrey;
      margin-right: 16px;
      overflow: scroll;
    }
  }

  &__cart {
    width: 20%;
    min-width: 300px;
    text-align: left;
    background-color: white;
    padding: 16px;

    display: flex;
    flex-direction: column;

    &__items {
      flex-grow: 1;

      li {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          $cross-size: 24px;

          width: $cross-size;
          height: $cross-size;
          line-height: $cross-size;
          margin-right: 12px;

          background: $pink;
          border-radius: 50%;
          font-weight: 300;
          color: white;
          font-size: 12px;

          text-align: center;
        }

        span:last-of-type {
          text-align: right;
          flex-grow: 1;
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;

      &__payment {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }
    }

    &__totals {
      font-size: 2rem;
      margin-bottom: 36px;

      p {
        display: flex;
        justify-content: space-between;
      }
    }

    .button {
      text-transform: uppercase;
      font-size: 0.8rem;
      width: 100%;
      background-color: $pink;
      margin: 0;
      margin-top: 8px;
      padding: 16px 32px;
      text-align: center;
      font-weight: 600;
      border-radius: 8px;

      &--secondary {
        border: 1px solid $pink;
        background: transparent;
      }
    }
  }
}
